import { Environment } from "./environment-config";

export const environment: Environment = {
  production: true,
  backendBaseUrl: 'https://app.staging.propup.at/graphql',
  OBJECT_BACKEND_URL: 'https://api.staging.propup.at/object-service/',
  SENTRY_ENVIRONMENT: 'staging',
  staticToggles: {
    enableLoggingTracing: false,
    enableLoadingPage: true,
    showFeedbackModal: true,
    showObjectImages: true,
    enableServicePage: true,
    enableOwnerSigning: true,
    enableLoanCalculator: true
  }
}