import { gql } from 'apollo-angular';
import { OwnerOverview } from '@frontend/models';

export interface GraphQLResponseOwnerOverview {
  ownerOverview: OwnerOverview;
}

export const GET_OWNER_OVERVIEW = gql`
  query GetOwnerOverview($ownerPortalId: String!) {
    ownerOverview(ownerPortalId: $ownerPortalId) {
      agent {
        id
        email
        firstName
        lastName
        picture
        officeName
        legalEntity
        isRemax
        themeConfig
        phone
        countrySettings
      }
      property {
        id
        title
        objectType
        orderType
        agent {
          id
          email
          firstName
          lastName
          phone
        }
        address {
          country
          city
          state
          zipCode
          streetName
          buildingNumber
          doorNumber
          stairway
        }
        roomsAndArea {
          livingArea {
            area
            areaCa
          }
          baseArea {
            area
            areaCa
          }
          usableArea {
            area
            areaCa
          }
        }
        price
        realtyPrice {
          purchase_price {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          rent {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          parking {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          garage {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          operating {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          heating {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          hot_water {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          cooling {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          maintenance_funds {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          elevator {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          others {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
        }
        creationDate
        images {
          id
          storageUrl
          type
          description
          order
        }
        displayImages
      }
      portalLanguage
      customerType
      customersId
      businessCustomerId
      rentalOffers {
        _id
        objectId
        state
        signatureData {
          date
          location
          signedDocumentUrl
        }
        tenantName
        totalRentPrice
        landlordAcceptance
        tenantRestriction {
          noLimitation
          limitationNotes
        }
        timeLimitation {
          date
        }
        generalInformation {
          rentalStart
          rentalDuration
          rentalEnd
        }
      }
      buyingOffers {
        _id
        objectId
        state
        signatureData {
          date
          location
          signedDocumentUrl
        }
        buyerName
        totalBuyPrice
        sellersAcceptance {
          sellerAcceptance
          changeAcceptanceUntil
          changeDetails
        }
        buyersAcceptanceWithChange {
          buyersAcceptanceWithChange
        }
        contractTerms {
          contractValidUntil
          alternativeValidity
          restriction
          restrictionDetails
        }
        propertyDetails {
          price
          includingAllTaxes
        }
        ownerCustomerPortal {
          showBuyerDecision
        }
      }
      exclusiveAgreement {
        _id
        objectId
        state
        signatureData {
          date
          location
          signedDocumentUrl
        }
        generalConditions {
          contractStart
          contractValidUntil
          validInMonths
          prolongationOption
          contractNotes
          isAwareOfCreditHistory
        }
        ownerPortal {
          isAvailableForOwner
          isSigningAllowed
          ownerSubmittedForReview
          isOwnerDataApplied
        }
      }
    }
  }
`;